

.dividends-table {
    @apply pt-20 pb-1 w-full mx-auto;
}

.dividends-table th {
    @apply text-sm font-normal bg-card text-white p-3 text-left  border border-card-off;
}

.dividends-table th.header-sortable {
    @apply cursor-pointer;
}

.dividends-table .number {
    font-variant-numeric: tabular-nums;
    @apply text-right;
}

.dividends-table .text {
    @apply text-left;
}

.dividends-table td {
    @apply  text-sm px-3 py-1 border border-card-off;
}


.dividends-table .empty-td {
    @apply text-center py-5;
}

.dividends-table tbody {
    @apply w-full;
}

.dividends-table tr {
    @apply rounded;
}

.dividends-table tr:nth-child(even) {
    @apply bg-card;
}

.dividends-table tr:nth-child(odd) {
    @apply bg-card;
}

.dividends-table tr.clickable-row  {
    @apply text-secondary hover:text-white ;
}
.dividends-table tr.clickable-row  {
    @apply hover:bg-card-off cursor-pointer ;
}

.dividends-table th.header-highlight {
    @apply bg-card-off ;
}

.dividends-table td.header-highlight {
    @apply text-gray-400
}

