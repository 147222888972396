@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@font-face {
  font-family: geist;
  src: url(fonts/GeistMono-Regular.woff2);
}

@font-face {
  font-family: geist;
  src: url(fonts/GeistMono-Bold.woff2);
  font-weight: bold;
}

@font-face {
  font-family: geist;
  src: url(fonts/GeistMono-Medium.woff2);
  font-weight: medium;
}

@font-face {
  font-family: geist;
  src: url(fonts/GeistMono-Black.woff2);
  font-weight: 900;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'geist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.monofont {
  font-family: 'JetBrains Mono', sans-serif;
}


input[type="file"] {
  display: none;
}

@layer base {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
}